import { Injectable } from '@angular/core';
import { Depenses, DuplicatedTacheRequestModel, NoteDepensesProjet, Scenario } from '@shared-ui';
import { BehaviorSubject } from 'rxjs';
import { HistoryValue } from '@shared-ui';

@Injectable()
export class DepensesInstructeurService {
  private isShowing = new BehaviorSubject<boolean>(false);
  private isShowingForScenario = new BehaviorSubject<boolean>(false);
  private activeScenario = new BehaviorSubject<Scenario | null>(null);
  private scenarios = new BehaviorSubject<Scenario[] | null>(null);
  private depenses = new BehaviorSubject<Depenses | null>(null);
  private duplicatedTacheRequest = new BehaviorSubject<DuplicatedTacheRequestModel | null>(null);
  private isShowingCommentaire = new BehaviorSubject<boolean>(false);
  private commentaire = new BehaviorSubject<{
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  }>(null);
  commentaireDepense = new BehaviorSubject<{
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  }>(null);
  private tabActive = new BehaviorSubject<string>('');
  private commentaireToSave = new BehaviorSubject<{
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  }>(null);

  private commentaireDepenseToSave = new BehaviorSubject<{
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number; historyValues: HistoryValue<number>[] };
    columnName: string;
  }>(null);

  setIsShowing(data: boolean) {
    this.isShowing.next(data);
  }

  getIsShowing() {
    return this.isShowing.asObservable();
  }

  getIsShowingForScenario() {
    return this.isShowingForScenario.asObservable();
  }

  setIsShowingForScenario(data: boolean) {
    this.isShowingForScenario.next(data);
  }

  setActiveScenario(data: Scenario) {
    this.activeScenario.next(data);
  }

  getActiveScenario() {
    return this.activeScenario.asObservable();
  }

  setDepenses(data: Depenses) {
    this.depenses.next(data);
  }

  getDepenses() {
    return this.depenses.asObservable();
  }

  getIsShowingCommentaire() {
    return this.isShowingCommentaire.asObservable();
  }

  setIsShowingCommentaire(data: boolean) {
    this.isShowingCommentaire.next(data);
    this.isShowing.next(false);
  }

  setCommentaires(param: {
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number; historyValues: HistoryValue<number>[] };
    columnName: string;
  }) {
    this.commentaire.next(param);
  }

  setCommentairesDepenses(param: {
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number; historyValues: HistoryValue<number>[] };
    columnName: string;
  }) {
    this.commentaireDepense.next(param);
  }

  getCommentaires() {
    return this.commentaire.asObservable();
  }
  getCommentairesDepense() {
    return this.commentaireDepense.asObservable();
  }
  setCommentaireToSave(param: {
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number; historyValues: HistoryValue<number>[] };
    columnName: string;
  }) {
    this.commentaireToSave.next(param);
  }
  getCommentaireToSave() {
    return this.commentaireToSave.asObservable();
  }
  getCommentaireDepenseToSave() {
    return this.commentaireDepenseToSave.asObservable();
  }
  setCommentaireDepenseToSave(param: {
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number; historyValues: HistoryValue<number>[] };
    columnName: string;
  }) {
    this.commentaireDepenseToSave.next(param);
  }

  setTabActive(tab: string) {
    this.tabActive.next(tab);
  }
  getTabActive() {
    return this.tabActive.asObservable();
  }

  setDuplicatedTacheRequest(data: DuplicatedTacheRequestModel) {
    this.duplicatedTacheRequest.next(data);
  }

  getDuplicatedTacheRequest() {
    return this.duplicatedTacheRequest.asObservable();
  }

  getScenarios(): BehaviorSubject<Scenario[] | null> {
    return this.scenarios;
  }

  setScenarios(value: Scenario[] | null) {
    this.scenarios.next(value);
  }
}
