import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Depenses, EnumStatutScenario, Scenario } from '@shared-ui';
import { Observable, of, switchMap } from 'rxjs';
import { DuplicatedTacheRequestModel } from '@shared-ui';
import { map } from 'rxjs/operators';
import { environment } from '@environments-evaluateur/environment';

@Injectable()
export class DepensesInstructeurHttpService {
  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  private getApiUrl(): string {
    return this.apiUrl;
  }

  getScenarios(id: string): Observable<HttpResponse<Scenario[]>> {
    return this.httpClient.get<Scenario[]>(this.getApiUrl() + 'depenses-projet/projets/' + id + '/scenarios', {
      observe: 'response',
    });
  }

  putScenario(id: string, scenarioNewName: string): Observable<HttpResponse<Depenses>> {
    const body = JSON.stringify({ scenario: scenarioNewName });
    return this.httpClient.put<Depenses>(this.getApiUrl() + 'depenses-projet/' + id + '/scenario', body, {
      observe: 'response',
    });
  }

  postScenario(id: string, scenarioName: string): Observable<HttpResponse<Depenses>> {
    const body = JSON.stringify({ scenario: scenarioName });
    return this.httpClient.post<Depenses>(this.getApiUrl() + 'depenses-projet/' + id, body, {
      observe: 'response',
    });
  }

  getDepensesProjet(id: string): Observable<HttpResponse<Depenses>> {
    return this.httpClient.get<Depenses>(this.getApiUrl() + 'depenses-projet/' + id, {
      observe: 'response',
    });
  }

  getValidatedDepensesProjet(idProjet: string, validStatus: EnumStatutScenario[]): Observable<HttpResponse<Depenses> | undefined> {
    return this.getScenarios(idProjet).pipe(
      map(response => response.body!),
      map(scenarios => scenarios.find(scenario => scenario.actif && validStatus.includes(scenario.statutScenario))),
      switchMap(scenario => (scenario ? this.getDepensesProjet(scenario.idDepensesProjet) : of(undefined)))
    );
  }
  postScenarioActif(id: string): Observable<HttpResponse<Depenses>> {
    return this.httpClient.post<Depenses>(this.getApiUrl() + 'depenses-projet/' + id + '/actif', null, {
      observe: 'response',
    });
  }

  deleteScenario(id: string): Observable<HttpResponse<Depenses>> {
    return this.httpClient.delete<Depenses>(this.getApiUrl() + 'depenses-projet/' + id, {
      observe: 'response',
    });
  }

  putDepensesProjet(id: string, depenses: Depenses): Observable<HttpResponse<Depenses>> {
    const body = JSON.stringify(depenses);
    return this.httpClient.put<Depenses>(this.getApiUrl() + 'depenses-projet/' + id + '/analyse-depenses', body, {
      observe: 'response',
    });
  }

  updateStatusDepenseProjet(id: string, statusDepenses: EnumStatutScenario): Observable<HttpResponse<Depenses>> {
    const body = JSON.stringify(statusDepenses);
    return this.httpClient.put<Depenses>(this.getApiUrl() + 'depenses-projet/' + id + '/statut', body, {
      observe: 'response',
    });
  }

  duplicateTache(id: string, duplicatedTacheRequest: DuplicatedTacheRequestModel): Observable<HttpResponse<Depenses>> {
    const body = JSON.stringify(duplicatedTacheRequest);
    return this.httpClient.post<Depenses>(this.getApiUrl() + 'depenses-projet/' + id + '/taches', body, {
      observe: 'response',
    });
  }

  updateZoneDeForcage(id: string, zoneDeForcage: any): Observable<HttpResponse<Depenses>> {
    const body = JSON.stringify(zoneDeForcage);
    return this.httpClient.put<Depenses>(this.getApiUrl() + 'depenses-projet/' + id + '/versement', body, {
      observe: 'response',
    });
  }

  removeStructureFromScenario(id: string, idStructure: string): Observable<Depenses> {
    return this.httpClient.delete<Depenses>(this.getApiUrl() + 'depenses-projet/' + id + '/structure/' + idStructure);
  }
}
