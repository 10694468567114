import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { DemandeComplements, DocumentComplementaire, EnumScope } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable()
export class DemandeDeDocumentComplementaireService {
  demandeComplementsUrl = environment.apiUrl + 'demande-complements';
  demandeComplementsInstructeurUrl = environment.apiUrl + 'instructeur/demande-complements';

  constructor(private httpClient: HttpClient) {}

  postDemandeDocumentComplementaire(demande: DocumentComplementaire): Observable<HttpResponse<DocumentComplementaire>> {
    return this.httpClient.post<any>(this.demandeComplementsUrl, JSON.stringify(demande), {
      observe: 'response',
    });
  }

  getDemandesComplements(idProjet: string, idStructure?: string): Observable<HttpResponse<DemandeComplements[]>> {
    const scope = idStructure ? EnumScope.STRUCTURE : EnumScope.PROJET;
    let queryParams = '?projetId=' + idProjet;
    queryParams += idStructure ? '&structureId=' + idStructure : '';
    queryParams += '&scope=' + scope;
    return this.httpClient.get<DemandeComplements[]>(this.demandeComplementsInstructeurUrl + queryParams, { observe: 'response' });
  }
}
